import React, { useEffect, useState } from "react";
import { useGetListProjectQuery } from "@redux/queries/admin/admin.project";
import { useGetListSchoolTypeQuery } from "@redux/queries/admin/admin.school.type";
import { Button, Col, Collapse, Form, Input, Row, Select, Space } from "antd";
import { useGetListAreaQuery } from "@redux/queries/admin/admin.area";
import { useGetListCityQuery } from "@redux/queries/admin/admin.city";
import { useGetListScholarshipTypeQuery } from "@redux/queries/admin/admin.scholarship.type";
import { useGetListScholarshipQuery } from "@redux/queries/admin/admin.scholarship";
import { useGetListMajorQuery } from "@redux/queries/admin/admin.majors";
import { useGetListSpecializedQuery } from "@redux/queries/admin/admin.specialized";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
}

const FilterSchool: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [listCitySelected, setListCitySelected] = useState<any[]>([]);
    const [listScholarshipSelected, setListScholarshipSelected] = useState<any[]>([]);
    const [listSpecialized, setListSpecialized] = useState<any[]>([]);

    useEffect(() => {
        form.setFieldsValue(props.data);
    }, [props.data]);

    const {
        data: list_scholarship,
        isLoading: isLoadingScholarship,
        error: errorScholarship,
    } = useGetListScholarshipQuery();
    const { data: list_area, isLoading: isLoadingArea, error: errorArea } = useGetListAreaQuery();
    const { data: list_city, isLoading: isLoadingCity, error: errorCity } = useGetListCityQuery();
    const {
        data: list_scholarship_type,
        isLoading: isLoadingScholarshipType,
        error: errorScholarshipType,
    } = useGetListScholarshipTypeQuery();
    const {
        data: list_school_type,
        isLoading: isLoadingSchoolType,
        error: errorSchoolType,
    } = useGetListSchoolTypeQuery();
    const { data: list_project, isLoading: isLoadingProject, error: errorProject } = useGetListProjectQuery();
    const { data: list_major, isLoading: isLoadingMajor, error: errorMajor } = useGetListMajorQuery({});
    const {
        data: list_specialized,
        isLoading: isLoadingSpecialized,
        error: errorSpecialized,
    } = useGetListSpecializedQuery();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    useEffect(() => {
        setListCitySelected(list_city || []);
    }, [list_city]);

    useEffect(() => {
        setListScholarshipSelected(list_scholarship || []);
    }, [list_scholarship]);

    useEffect(() => {
        setListSpecialized(list_specialized || []);
    }, [list_specialized]);

    const handleChangeValue = (changedValues: any, allValues: any) => {
        if (changedValues.areas) {
            if (changedValues.areas.length === 0) {
                setListCitySelected(list_city || []);
                return;
            }
            const listCity = list_city?.filter((o: ICity) => {
                return changedValues.areas.includes(o.areaId);
            });
            setListCitySelected(listCity || []);
        }
        if (changedValues.scholarshipTypes) {
            if (changedValues.scholarshipTypes.length === 0) {
                setListScholarshipSelected(list_scholarship || []);
                return;
            }
            const listScholarship = list_scholarship?.filter((o: any) => {
                return changedValues.scholarshipTypes.includes(o.scholarshipTypeId);
            });
            setListScholarshipSelected(listScholarship || []);
        }
        if (changedValues.majors) {
            if (changedValues.majors.length === 0) {
                setListSpecialized(list_specialized || []);
                return;
            }
            const listSpecialized = list_specialized?.filter((o: any) => {
                return changedValues.majors.includes(o.majorId);
            });
            setListSpecialized(listSpecialized || []);
        }
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={handleChangeValue}
                >
                    <Collapse
                        bordered={false}
                        ghost
                    >
                        <Collapse.Panel
                            header="Tìm kiếm nâng cao"
                            key="1"
                        >
                            <Row>
                                {/* col 1 */}
                                <Col span={8}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="areas"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        mode="multiple"
                                                        allowClear
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {list_area &&
                                                            list_area.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.language[0].title}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Tỉnh</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={"scholarshipTypes"}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        allowClear
                                                        mode="multiple"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {list_scholarship_type &&
                                                            list_scholarship_type.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.language[0].title}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Loại học bổng</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={"scholarships"}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        allowClear
                                                        mode="multiple"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {listScholarshipSelected &&
                                                            listScholarshipSelected.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.language[0].title}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Học bổng</label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                {/* col 2 */}
                                <Col span={8}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="cities"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        mode="multiple"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        allowClear
                                                    >
                                                        {listCitySelected &&
                                                            listCitySelected.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.language[0].title}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Thành phố</label>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col span={12}>
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            name="rankFrom"
                                                            className="form-floating"
                                                            rules={[
                                                                {
                                                                    pattern: /^[0-9\b]+$/,
                                                                    message: "Phải nhập số",
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                className="form-control form-floating"
                                                                placeholder=""
                                                            />
                                                        </Form.Item>
                                                        <label>Rank Từ</label>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            name="rankTo"
                                                            className="form-floating"
                                                            rules={[
                                                                {
                                                                    pattern: /^[0-9\b]+$/,
                                                                    message: "Phải nhập số",
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                className="form-control form-floating"
                                                                placeholder=""
                                                            />
                                                        </Form.Item>
                                                        <label>Đến</label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="projects"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        allowClear
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                    >
                                                        {list_project &&
                                                            list_project.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.name}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Dự án</label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col span={8}>
                                    <div className="body-component">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={"majors"}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        allowClear
                                                        mode="multiple"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {list_major &&
                                                            list_major.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.language[0].title}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Ngành học</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="specializes"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        allowClear
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                    >
                                                        {listSpecialized &&
                                                            listSpecialized.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.language[0].title}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Chuyên ngành</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="types"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        mode="multiple"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        allowClear
                                                    >
                                                        {list_school_type &&
                                                            list_school_type.map((o: any, i: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={o.id}
                                                                        value={o.id}
                                                                    >
                                                                        {o.language[0].title}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Loại hình trường</label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                    <Row>
                        {/* col 1 */}
                        <Col
                            span={8}
                            offset={16}
                        >
                            <Row justify="space-between">
                                <Col span={16}>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="s"
                                                className="form-floating"
                                            >
                                                <Input
                                                    className="form-control form-floating"
                                                    placeholder=""
                                                    style={{ width: "100%" }}
                                                />
                                            </Form.Item>
                                            <label>Keyword</label>
                                        </div>
                                    </div>
                                </Col>
                                <Space>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Search
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            onClick={() => {
                                                props.onSearch({ s: undefined });
                                                form.resetFields();
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterSchool;
