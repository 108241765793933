import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/school/scholarship-training";

export const adminSchoolScholarshipTrainingApi = createApi({
    reducerPath: "adminSchoolScholarshipTrainingApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["SchoolScholarshipTraining"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSchoolScholarshipTraining: builder.query<Array<any>, any>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "SchoolScholarshipTraining" as const, id })),
                              { type: "SchoolScholarshipTraining", id: "SchoolScholarshipTraining-LIST" },
                          ]
                        : [{ type: "SchoolScholarshipTraining", id: "SchoolScholarshipTraining-LIST" }],
            }),
            getListSchoolScholarshipTrainingPaginate: builder.query<
                IResponseDataAdmin<any>,
                { page: number; limit: number; schoolId?: string; schoolScholarshipId?: string; trainingId?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "SchoolScholarshipTraining" as const, id })),
                              { type: "SchoolScholarshipTraining", id: "SchoolScholarshipTraining-LIST" },
                          ]
                        : [{ type: "SchoolScholarshipTraining", id: "SchoolScholarshipTraining-LIST" }],
            }),
        };
    },
});

export const {
    useGetListSchoolScholarshipTrainingQuery,
    useGetListSchoolScholarshipTrainingPaginateQuery,
    useLazyGetListSchoolScholarshipTrainingQuery,
} = adminSchoolScholarshipTrainingApi;
