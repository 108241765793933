import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import {
    useCreateSchoolNewsMutation,
    useGetSchoolNewsByIdQuery,
    useUpdateSchoolNewsMutation,
} from "@redux/queries/admin/admin.school.news";
import { history } from "@utils/helper-router";
import { Button, Card, Form, message, Row, Space } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GeneralTab from "../components/GeneralTab";
import dayjs from "dayjs";
import moment from "moment";
import { E_TRAINING_FORMAT, LANGUAGE, SUPPORT_MODE, TYPE_OF_ADMISSION } from "../TypeInput";

export interface FormSchoolValues {
    title_vi?: string;
    title_en?: string;
    title_cn?: string;
    linkDetail?: string;
    rank?: number;
    code: string;
    projectIds?: string;
    schoolTypeId?: string;
}

interface Tranings {
    trainingId: string;
    programs: string;
}

interface SchoolNewsScholarship {
    admissionPeriod: Array<string>;
    year: number;
    expireApply: number;
    feeApply?: number;
    typeOfAdmission?: TYPE_OF_ADMISSION;
    supportMode?: SUPPORT_MODE;
    trainingFormat?: E_TRAINING_FORMAT;
    scholarshipGroup?: string;
    scholarshipType?: string;
    trainings: Array<Tranings>;
    majorSpecializationIds: string[];
    trainingLanguage: LANGUAGE;
}

const Create: React.FunctionComponent = () => {
    const [createSchoolNews, result] = useCreateSchoolNewsMutation();
    const [updateSchoolNews, resultUpdate] = useUpdateSchoolNewsMutation();
    const { showToast } = useToast();
    const location = useLocation();
    const id = location.pathname.split("/")[4];

    const { data: dataEdit, isLoading } = useGetSchoolNewsByIdQuery({
        id,
    });

    const [editThumbnail, setEditThumbnail] = useState<any>(null);
    const [checkedList, setCheckedList] = useState<any[]>([]);
    const [highLever, setHighLever] = useState<boolean>();
    const [tagsVi, setTagsVi] = useState<{ key: number; value: string }[]>([]);
    const [tagsEn, setTagsEn] = useState<{ key: number; value: string }[]>([]);
    const [tagsCn, setTagsCn] = useState<{ key: number; value: string }[]>([]);

    useEffect(() => {
        if (dataEdit) {
            let schoolNewsScholarship = null;
            if (dataEdit?.schoolNewsScholarship) {
                try {
                    schoolNewsScholarship = dataEdit.schoolNewsScholarship;
                } catch (error) {
                    schoolNewsScholarship = null;
                }
            }
            const titleVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.title;
            const titleEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.title;
            const titleCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.title;
            const introduceVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.content;
            const introduceEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.content;
            const introduceCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.content;
            const slugVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.slug;
            const slugEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.slug;
            const slugCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.slug;
            const shortContentVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.shortContent;
            const shortContentEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.shortContent;
            const shortContentCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.shortContent;
            const thumbnail = dataEdit?.thumbnail;
            const status = dataEdit?.status;
            const categoryId = dataEdit?.category?.id;
            const schoolId = dataEdit?.school?.id;
            const expireApply = schoolNewsScholarship ? dayjs.unix(schoolNewsScholarship.expireApply) : null;

            const tagsVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.tags;
            const tagsEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.tags;
            const tagsCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.tags;
            const arrayTagsVi = tagsVi && tagsVi.map((item, index: number) => ({ key: index, value: item }));
            setTagsVi(arrayTagsVi ? arrayTagsVi : []);
            const arrayTagsEn = tagsEn && tagsEn.map((item, index: number) => ({ key: index, value: item }));
            setTagsEn(arrayTagsEn ? arrayTagsEn : []);
            const arrayTagsCn = tagsCn && tagsCn.map((item, index: number) => ({ key: index, value: item }));
            setTagsCn(arrayTagsCn ? arrayTagsCn : []);

            setEditThumbnail(thumbnail);
            setIntroduceVi(introduceVi || "");
            setIntroduceEn(introduceEn || "");
            setIntroduceCn(introduceCn || "");
            setCheckedList(dataEdit?.relates || []);
            form.setFieldsValue({
                titleVi,
                titleEn,
                titleCn,
                introduceVi,
                introduceEn,
                introduceCn,
                slugVi,
                slugEn,
                slugCn,
                // tagsVi,
                // tagsEn,
                // tagsCn,
                thumbnail,
                status,
                categoryId,
                schoolId,
                // schoolNewsScholarship,
                shortContentVi,
                shortContentEn,
                shortContentCn,
                ...schoolNewsScholarship,
                expireApply,
            });
        }
    }, [dataEdit]);

    const [form] = Form.useForm();

    const [thumbnail, setThumbnail] = useState<any>(null);

    const [introduceVi, setIntroduceVi] = useState<any>("");
    const [introduceEn, setIntroduceEn] = useState<any>("");
    const [introduceCn, setIntroduceCn] = useState<any>("");
    const [errorContent, setErrorContent] = useState<string>();
    const [listMajor, setListMajor] = useState<string[]>();
    const [disableBtn, setDisableBtn] = useState<boolean>(false);

    useEffect(() => {
        if (errorContent) {
            message.error("Vui lòng nhập đủ thông tin");
        }
    }, [errorContent]);

    const handleSubmit = async (isOpenNewTab = false) => {
        let value;
        try {
            value = await form.validateFields();
            if (!introduceVi) {
                setErrorContent("introduceVi");
                return;
            }
            if (!introduceEn) {
                setErrorContent("introduceEn");
                return;
            }
            if (!introduceCn) {
                setErrorContent("introduceCn");
                return;
            }
        } catch (errorInfo) {
            const fieldError = (errorInfo as any)?.errorFields[0].name[0];
            if (fieldError === "titleVi" || fieldError === "titleEn" || fieldError === "titleCn") {
                setErrorContent(fieldError);
            }
            return;
        }
        setDisableBtn(true);

        const language = [
            {
                lang: "vi",
                title: value.titleVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.title,
                shortContent:
                    value.shortContentVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.shortContent,
                content: introduceVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.content,
                slug: value.slugVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.slug,
                tags:
                    tagsVi?.map((item: any) => item.value) ||
                    dataEdit?.language?.find((item: any) => item.lang === "vi")?.tags,
            },
            {
                lang: "en",
                title: value.titleEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.title,
                shortContent:
                    value.shortContentEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.shortContent,
                content: introduceEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.content,
                slug: value.slugEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.slug,
                tags:
                    tagsEn?.map((item: any) => item.value) ||
                    dataEdit?.language?.find((item: any) => item.lang === "en")?.tags,
            },
            {
                lang: "cn",
                title: value.titleCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.title,
                shortContent:
                    value.shortContentCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.shortContent,
                content: introduceCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.content,
                slug: value.slugCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.slug,
                tags:
                    tagsCn?.map((item: any) => item.value) ||
                    dataEdit?.language?.find((item: any) => item.lang === "cn")?.tags,
            },
        ];

        const listMajorSpecialization = listMajor?.map((item: any) => item.specialized.id);

        const schoolNewsScholarship: SchoolNewsScholarship = {
            admissionPeriod: value.admissionPeriod,
            year: value.year,
            expireApply: moment(value.expireApply).unix(),
            feeApply: value.feeApply ? Number(value.feeApply) : 0,
            typeOfAdmission: value.typeOfAdmission,
            supportMode: value.supportMode,
            trainingFormat: value.trainingFormat,
            scholarshipGroup: value.scholarshipGroup,
            scholarshipType: value.scholarshipType,
            majorSpecializationIds:
                value.majorSpecializationIds && value.majorSpecializationIds.length !== 0
                    ? value.majorSpecializationIds
                    : listMajorSpecialization,
            trainingLanguage: value.trainingLanguage,
            trainings: value.trainings,
        };

        const data = _.pickBy(
            {
                language,
                thumbnail: thumbnail || dataEdit?.thumbnail,
                // tags: value.tags.filter((item: any) => item),
                status: value.status,
                categoryId: value.categoryId,
                schoolId: value.schoolId,
                relates: checkedList ? checkedList : [],
                // schoolNewsScholarships: [],
                schoolNewsScholarship: highLever ? schoolNewsScholarship : null,
            },
            (item) => item !== undefined && item !== null && item !== ""
        );

        const result = id
            ? await updateSchoolNews({
                  ...data,
                  id,
              } as any)
            : await createSchoolNews(data as any);
        if ("error" in result) {
            id ? showToast({ ...TOAST_UPDATE_ERROR }) : showToast({ ...TOAST_CREATE_ERROR });
            return null;
        }

        if ("data" in result) {
            id ? showToast({ ...TOAST_UPDATE_SUCCESS }) : showToast({ ...TOAST_CREATE_SUCCESS });
            !isOpenNewTab && history.navigate("/admin/school-news");
            return result.data;
        }
    };

    const handleSubmitAndOpenNewTab = async () => {
        const data = await handleSubmit(true);
        if (data) {
            const newTabUrl = dataEdit
                ? `https://studyinchina.io/tin-tuc/${dataEdit.id}`
                : `https://studyinchina.io/tin-tuc/${data.id}`;
            window.open(newTabUrl, "_blank");
            form.resetFields();
        }
    };

    return (
        <>
            <div className="container-fluid padding0">
                <span className="screen-darken"></span>
                <main>
                    <div>
                        <section id="content-main">
                            <Form
                                form={form}
                                scrollToFirstError
                                initialValues={dataEdit}
                            >
                                <div className="box-component">
                                    <div>
                                        <Card
                                            title="Thêm mới tin tức"
                                            bordered={false}
                                            style={{ width: "100%" }}
                                            loading={isLoading}
                                        >
                                            <GeneralTab
                                                form={form}
                                                introduceVi={introduceVi}
                                                introduceEn={introduceEn}
                                                introduceCn={introduceCn}
                                                setIntroduceVi={(value: any) => setIntroduceVi(value)}
                                                setIntroduceEn={(value: any) => setIntroduceEn(value)}
                                                setIntroduceCn={(value: any) => setIntroduceCn(value)}
                                                thumbnail={thumbnail}
                                                setThumbnail={(value: any) => setThumbnail(value)}
                                                editThumbnail={editThumbnail}
                                                errorContent={errorContent}
                                                setErrorContent={(value: any) => setErrorContent(value)}
                                                dataEdit={dataEdit}
                                                setListMajor={(values: any) => setListMajor(values)}
                                                setCheckedList={(values: any) => setCheckedList(values)}
                                                setHighLever={(bool: any) => setHighLever(bool)}
                                                setTagsVi={(data: any) => setTagsVi(data)}
                                                setTagsEn={(data: any) => setTagsEn(data)}
                                                setTagsCn={(data: any) => setTagsCn(data)}
                                                tagsVi={tagsVi}
                                                tagsEn={tagsEn}
                                                tagsCn={tagsCn}
                                            />
                                        </Card>
                                    </div>
                                    <Row
                                        justify="center"
                                        gutter={2}
                                    >
                                        <Space>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={handleSubmitAndOpenNewTab}
                                                    disabled={disableBtn}
                                                >
                                                    Submit and open new tab
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={() => handleSubmit()}
                                                    disabled={disableBtn}
                                                >
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    onClick={() => {
                                                        history.navigate("/admin/school-news");
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Form.Item>
                                        </Space>
                                    </Row>
                                </div>
                            </Form>
                        </section>
                    </div>
                </main>
            </div>
        </>
    );
};

export default Create;
