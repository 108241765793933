import { Pagination } from "@components/pagination";
import {
    useDeleteScholarshipMutation,
    useGetListScholarshipPaginateQuery,
    useDeleteMultiScholarshipMutation,
} from "@redux/queries/admin/admin.scholarship";
import React, { ChangeEvent, useEffect, useState } from "react";
import AddScholarship from "./components/AddScholarship";
import UpdateScholarship from "./components/EditScholarship";
import FilterScholarship from "./components/FilterScholarship";
import { useToast } from "@components/toast/ToastProvider";
import { useModal } from "@components/modal/ModalProvider";
import { TOAST_DELETE_ERROR, TOAST_DELETE_SUCCESS } from "@consts";
import moment from "moment";

const Scholarship: React.FunctionComponent = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [selectedScholarship, setSelectedScholarship] = useState<IScholarship | null>(null);
    const [filter, setFilter] = useState<{
        title?: string;
    }>({
        title: "",
    });
    const [ids, setIds] = useState<Array<string>>([]);

    const { data, isLoading, isFetching } = useGetListScholarshipPaginateQuery({
        page,
        limit,
        title: filter.title,
    });
    const [deleteScholarship, isLoadingDelete] = useDeleteScholarshipMutation();
    const [deleteMulti, isLoadingDeleteMulti] = useDeleteMultiScholarshipMutation();

    const { showToast } = useToast();
    const { showModal, onSubmitModal } = useModal();

    const handleFilterChange = (values: any) => {
        setFilter(values);
        setPage(1);
        setIds([]);
    };

    const submitModal = async (id?: string) => {
        if (id) {
            const result = await deleteScholarship({
                id: id,
            });

            if ("error" in result) {
                showToast({ ...TOAST_DELETE_ERROR });
            }

            if ("data" in result) {
                showToast({ ...TOAST_DELETE_SUCCESS });
            }
        } else if (ids.length > 0) {
            const result = await deleteMulti({
                ids: ids,
            });

            if ("error" in result) {
                showToast({ ...TOAST_DELETE_ERROR });
            }

            if ("data" in result) {
                showToast({ ...TOAST_DELETE_SUCCESS });
            }
        }
        setIds([]);
        onSubmitModal();
    };

    const handleDeleteMulti = async () => {
        showModal({
            title: "Warning",
            button: (
                <>
                    <button
                        key="submit-modal"
                        type="submit"
                        onClick={() => submitModal()}
                        className="btn-blue"
                    >
                        <span className="ps-2">OK</span>
                    </button>
                </>
            ),
        });
    };

    const handleDeleteOne = (id: string) => {
        showModal({
            title: "Warning",
            button: (
                <>
                    <button
                        key="submit-modal"
                        type="submit"
                        onClick={() => submitModal(id)}
                        className="btn-blue"
                    >
                        <span className="ps-2">OK</span>
                    </button>
                </>
            ),
        });
    };

    const handleCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setIds([...ids, event.target.value]);
        } else {
            const new_ids = ids.filter((id) => id !== event.target.value);
            setIds(new_ids);
        }
    };

    const handleCheckAll = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            if (data) {
                const new_ids = data.items.map((o) => {
                    return o.id;
                });
                setIds(new_ids);
            }
        } else {
            setIds([]);
        }
    };

    useEffect(() => {
        setIds([]);
    }, [page]);

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <button
                                                type="button"
                                                className={ids.length > 0 ? "btn-red" : "btn-gray"}
                                                onClick={handleDeleteMulti}
                                                disabled={ids.length == 0}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </button>
                                            <AddScholarship />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <FilterScholarship
                                            onSearch={handleFilterChange}
                                            data={filter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={"all"}
                                                        onChange={handleCheckAll}
                                                        checked={ids.length == data?.items.length}
                                                        defaultChecked={false}
                                                    />
                                                </div>
                                            </th>
                                            <th className="text-start">Học bổng</th>
                                            <th className="text-start">Tên tiếng Anh</th>
                                            <th className="text-start">Tên tiếng Trung</th>
                                            <th className="text-start">Điểm đỗ mặc định</th>
                                            <th className="text-start">Loại học bổng</th>
                                            <th>Ngày tạo</th>
                                            {/* <th>Trạng thái</th> */}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isFetching && (
                                            <thead>
                                                <div className="center-loading">
                                                    <div className="throbber-loader cen"></div>
                                                </div>
                                            </thead>
                                        )}
                                        {!isFetching &&
                                            data &&
                                            data.items.map((o) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value={o.id}
                                                                        onChange={handleCheckBox}
                                                                        checked={ids.includes(o.id)}
                                                                        defaultChecked={false}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="text-start">{o.language[0].title}</td>
                                                            <td className="text-start">{o.language[1].title}</td>
                                                            <td className="text-start">{o.language[2].title}</td>
                                                            <td className="text-start">{o.passScore}</td>
                                                            <td className="text-start">
                                                                {o.scholarshipType &&
                                                                    o.scholarshipType?.language[0].title}
                                                            </td>
                                                            <td>
                                                                {o.createdAt &&
                                                                    moment(o.createdAt).format("DD/MM/YYYY")}
                                                            </td>
                                                            {/* <td>
                                                                <span className="text-box-green">Active</span>
                                                            </td> */}
                                                            <td style={{ width: 120 }}>
                                                                <div className="group-icon-action">
                                                                    <button
                                                                        type="button"
                                                                        className="text-green"
                                                                        onClick={() => setSelectedScholarship(o)}
                                                                    >
                                                                        <i className="bi-pencil-square"></i>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="text-red"
                                                                        onClick={() => handleDeleteOne(o.id)}
                                                                    >
                                                                        <i className="bi-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                <UpdateScholarship
                                    data={selectedScholarship}
                                    onClose={() => setSelectedScholarship(null)}
                                />
                                <Pagination
                                    onSizeChange={setLimit}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={setPage}
                                    defaultCurrentPage={1}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Scholarship;
