import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import {
    useGetListMajorQuery,
    useLazyGetListMajorPaginateQuery,
    useLazyGetListMajorQuery,
} from "@redux/queries/admin/admin.majors";
import {
    useCreateSpecializedMutation,
    useLazyGetSpecializedByIdQuery,
    useUpdateSpecializedMutation,
} from "@redux/queries/admin/admin.specialized";
import { Button, Card, Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import Tab from "./Tab";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";
import { useLocation, useNavigate } from "react-router-dom";

const FromCreateSpecialized = () => {
    const location = useLocation();
    const id = location.pathname.split("/")[4];
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [createSpecialized, isLoadingCreate] = useCreateSpecializedMutation();
    const [updateSpecialized, isLoadingUpdate] = useUpdateSpecializedMutation();
    const [getDataSpecialized, { data: specialized }] = useLazyGetSpecializedByIdQuery();

    const { showToast } = useToast();

    const [getMajor, { data: list_major }] = useLazyGetListMajorQuery();

    const onChangeTraining = (value: any) => {
        getMajor({
            child: "1",
            trainingId: value,
        });
    };
    const { data: list_train, isLoading: isLoadingTrain, error: error } = useGetListTrainingQuery();

    const [thumbnail, setThumbnail] = useState<any>(null);
    const [editThumbnail, setEditThumbnail] = useState<any>(null);

    const [introduceVi, setIntroduceVi] = useState<any>("");
    const [introduceEn, setIntroduceEn] = useState<any>("");
    const [introduceCn, setIntroduceCn] = useState<any>("");
    const [errorContent, setErrorContent] = useState<string>();

    useEffect(() => {
        if (id) {
            getDataSpecialized({ id });
        }
    }, [id]);

    useEffect(() => {
        if (specialized) {
            const introduceVi = specialized?.language?.find((item: any) => item.lang === "vi")?.content;
            const introduceEn = specialized?.language?.find((item: any) => item.lang === "en")?.content;
            const introduceCn = specialized?.language?.find((item: any) => item.lang === "cn")?.content;
            const shortContentVi = specialized?.language?.find((item: any) => item.lang === "vi")?.shortContent;
            const shortContentEn = specialized?.language?.find((item: any) => item.lang === "en")?.shortContent;
            const shortContentCn = specialized?.language?.find((item: any) => item.lang === "cn")?.shortContent;
            setIntroduceVi(introduceVi || "");
            setIntroduceEn(introduceEn || "");
            setIntroduceCn(introduceCn || "");
            form.setFieldsValue({
                ...specialized,
                titleVi: specialized?.language[0].title,
                titleEn: specialized?.language[1].title,
                titleCn: specialized?.language[2].title,
                slugVi: specialized?.language[0].slug,
                slugEn: specialized?.language[1].slug,
                slugCn: specialized?.language[2].slug,
                shortContentVi,
                shortContentEn,
                shortContentCn,
                majorId: specialized?.major?.id,
                trainingId: specialized?.training?.id,
                introduceVi,
                introduceEn,
                introduceCn,
            });
            setEditThumbnail(specialized?.thumbnail);
            if (specialized?.training?.id) {
                getMajor({
                    child: "1",
                    trainingId: specialized.training.id,
                });
            }
        } else {
            form.resetFields();
        }
    }, [specialized]);

    const onFinish = async (values: any) => {
        let value;
        try {
            value = await form.validateFields();
            if (!introduceVi) {
                setErrorContent("introduceVi");
                return;
            }
            if (!introduceEn) {
                setErrorContent("introduceEn");
                return;
            }
            if (!introduceCn) {
                setErrorContent("introduceCn");
                return;
            }
        } catch (errorInfo) {
            const fieldError = (errorInfo as any)?.errorFields[0].name[0];
            if (fieldError === "titleVi" || fieldError === "titleEn" || fieldError === "titleCn") {
                setErrorContent(fieldError);
            }
            return;
        }
        const language = [
            {
                lang: "vi",
                title: values.titleVi || specialized?.language?.find((item: any) => item.lang === "vi")?.title,
                content: values.introduceVi || specialized?.language?.find((item: any) => item.lang === "vi")?.content,
                shortContent:
                    values.shortContentVi ||
                    specialized?.language?.find((item: any) => item.lang === "vi")?.shortContent,
                slug: values.slugVi || specialized?.language?.find((item: any) => item.lang === "vi")?.slug,
            },
            {
                lang: "en",
                title: values.titleEn || specialized?.language?.find((item: any) => item.lang === "en")?.title,
                content: values.introduceEn || specialized?.language?.find((item: any) => item.lang === "en")?.content,
                shortContent:
                    values.shortContentEn ||
                    specialized?.language?.find((item: any) => item.lang === "en")?.shortContent,
                slug: values.slugEn || specialized?.language?.find((item: any) => item.lang === "en")?.slug,
            },
            {
                lang: "cn",
                title: values.titleCn || specialized?.language?.find((item: any) => item.lang === "cn")?.title,
                content: values.introduceCn || specialized?.language?.find((item: any) => item.lang === "cn")?.content,
                shortContent:
                    values.shortContentCn ||
                    specialized?.language?.find((item: any) => item.lang === "cn")?.shortContent,
                slug: values.slugCn || specialized?.language?.find((item: any) => item.lang === "cn")?.slug,
            },
        ];
        const data = {
            ...values,
            language,
            thumbnail: thumbnail || editThumbnail,
        };

        const result = id
            ? await updateSpecialized({
                  id: id,
                  ...data,
              })
            : await createSpecialized(data);
        if ("error" in result) {
            id ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            id ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setEditThumbnail(null);
            setThumbnail(null);
            navigate("/admin/specialized");
        }
    };

    return (
        <>
            <div className="container-fluid padding0">
                <span className="screen-darken"></span>
                <main>
                    <div>
                        <section id="content-main">
                            <div className="box-component">
                                <div className="body-component">
                                    <Card
                                        title={id ? "Sửa chuyên ngành" : "Thêm chuyên ngành"}
                                        bordered={false}
                                        style={{ width: "100%" }}
                                    >
                                        <Form
                                            form={form}
                                            onFinish={onFinish}
                                        >
                                            <div className="body-component">
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Tab
                                                            form={form}
                                                            thumbnail={thumbnail}
                                                            setThumbnail={(value: any) => setThumbnail(value)}
                                                            introduceVi={introduceVi}
                                                            introduceEn={introduceEn}
                                                            introduceCn={introduceCn}
                                                            editThumbnail={editThumbnail}
                                                            setIntroduceVi={(value: any) => setIntroduceVi(value)}
                                                            setIntroduceEn={(value: any) => setIntroduceEn(value)}
                                                            setIntroduceCn={(value: any) => setIntroduceCn(value)}
                                                            errorContent={errorContent}
                                                            setErrorContent={(value: any) => setErrorContent(value)}
                                                        />
                                                    </div>
                                                </div>
                                                <Row gutter={20}>
                                                    <Col span={12}>
                                                        <div className="form-group">
                                                            <div className="form-floating">
                                                                <Form.Item
                                                                    name="code"
                                                                    className="form-floating"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Thông tin bắt buộc",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        className="form-floating no-icon"
                                                                        placeholder=""
                                                                    />
                                                                </Form.Item>
                                                                <label>Code</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row gutter={20}>
                                                    <Col span={12}>
                                                        {" "}
                                                        <div className="form-group">
                                                            <div className="form-floating">
                                                                <Form.Item
                                                                    name="rating"
                                                                    className="form-floating"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Thông tin bắt buộc",
                                                                        },
                                                                        {
                                                                            pattern: /^[1-5]$/,
                                                                            message: "Chỉ được nhập số từ 1 đến 5",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        className="form-floating no-icon"
                                                                        placeholder=""
                                                                    />
                                                                </Form.Item>
                                                                <label>Rating</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <div className="form-group">
                                                            <div className="form-floating">
                                                                <Form.Item
                                                                    name="level"
                                                                    className="form-floating"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Thông tin bắt buộc",
                                                                        },
                                                                        {
                                                                            pattern: new RegExp(/^[0-9\b]+$/),
                                                                            message: "Chỉ được nhập số",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        className="form-floating no-icon"
                                                                        placeholder=""
                                                                    />
                                                                </Form.Item>
                                                                <label>Level</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row gutter={20}>
                                                    <Col span={12}>
                                                        {" "}
                                                        <div className="form-group">
                                                            <div className="form-floating">
                                                                <Form.Item
                                                                    name="rank"
                                                                    className="form-floating"
                                                                    rules={[
                                                                        // {
                                                                        //     required: true,
                                                                        //     message: "Thông tin bắt buộc",
                                                                        // },
                                                                        {
                                                                            pattern: new RegExp(/^[0-9\b]+$/),
                                                                            message: "Chỉ được nhập số",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        className="form-floating no-icon"
                                                                        placeholder=""
                                                                    />
                                                                </Form.Item>
                                                                <label>Rank</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={12}>
                                                        {" "}
                                                        <div className="form-group">
                                                            <div className="form-floating">
                                                                <Form.Item
                                                                    name="linkDetail"
                                                                    className="form-floating"
                                                                    rules={[
                                                                        {
                                                                            type: "url",
                                                                            message: "Sai định dạng url",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        className="form-floating no-icon"
                                                                        placeholder=""
                                                                    />
                                                                </Form.Item>
                                                                <label>Link Detail</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row gutter={20}>
                                                    <Col span={12}>
                                                        {" "}
                                                        <div className="form-group">
                                                            <div className="form-floating">
                                                                <Form.Item
                                                                    name="trainingId"
                                                                    className="form-floating"
                                                                >
                                                                    <Select
                                                                        className="form-control form-floating"
                                                                        bordered={false}
                                                                        onChange={onChangeTraining}
                                                                    >
                                                                        {list_train &&
                                                                            list_train.map((o: any, i: any) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        key={o.id}
                                                                                        value={o.id}
                                                                                    >
                                                                                        {o.language[0].title}
                                                                                    </Select.Option>
                                                                                );
                                                                            })}
                                                                    </Select>
                                                                </Form.Item>
                                                                <label>Hệ đào tạo</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <div className="form-group">
                                                            <div className="form-floating">
                                                                <Form.Item
                                                                    name="majorId"
                                                                    className="form-floating"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Thông tin bắt buộc",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        className="form-control form-floating"
                                                                        bordered={false}
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            (option?.children as any)
                                                                                ?.toLowerCase()
                                                                                .indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {list_major &&
                                                                            list_major.map((o: any, i: any) => {
                                                                                return (
                                                                                    <Select.Option
                                                                                        key={o.id}
                                                                                        value={o.id}
                                                                                    >
                                                                                        {o.language[0].title +
                                                                                            " - " +
                                                                                            o.code}
                                                                                    </Select.Option>
                                                                                );
                                                                            })}
                                                                    </Select>
                                                                </Form.Item>
                                                                <label>Chọn ngành học</label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className="w-100"
                                                    >
                                                        Submit
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    </Card>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </div>
        </>
    );
};

export default FromCreateSpecialized;
