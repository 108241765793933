import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import queryString from "query-string";

const BASE = "/api/majors";

export const adminMajorApi = createApi({
    reducerPath: "adminMajorApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Majors"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListMajor: builder.query<
                Array<IMajor>,
                {
                    title?: string;
                    code?: string;
                    child?: string;
                    trainingId?: string;
                    schoolId?: string;
                    parentId?: string;
                    parent?: string;
                }
            >({
                query: (query) => ({
                    url: `${BASE}/?` + queryString.stringify(query, { arrayFormat: "bracket" }),
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListMajorPaginate: builder.query<
                IResponseDataAdmin<IMajor>,
                { page: number; limit: number; title?: string; code?: string }
            >({
                query: (query) => {
                    const queryObject: any = {
                        url: `${BASE}/paginate?` + queryString.stringify(query, { arrayFormat: "bracket" }),
                        method: "GET",
                    };
                    return queryObject;
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Majors" as const, id })),
                              { type: "Majors", id: "MAJOR-LIST" },
                          ]
                        : [{ type: "Majors", id: "MAJOR-LIST" }],
            }),
            createMajor: builder.mutation<any, IAdminMajorInput>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: {
                        ...params,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Majors"],
            }),
            updateMajor: builder.mutation<any, IAdminMajorInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: {
                        ...params,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Majors"],
            }),
            deleteMajor: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Majors"],
            }),
            deleteMultiMajors: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Majors"],
            }),
        };
    },
});

export const {
    useGetListMajorQuery,
    useLazyGetListMajorQuery,
    useGetListMajorPaginateQuery,
    useLazyGetListMajorPaginateQuery,
    useCreateMajorMutation,
    useUpdateMajorMutation,
    useDeleteMajorMutation,
    useDeleteMultiMajorsMutation,
} = adminMajorApi;
