import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _, { update } from "lodash";

const BASE = "/api/ticket";

export const ticketApi = createApi({
    reducerPath: "ticketApi",
    tagTypes: ["Ticket", "TicketAnswer"],
    keepUnusedDataFor: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListTicket: builder.query<Array<ITicket>, void>({
                query: (query) => ({
                    url: `${BASE}`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListTicketPaginate: builder.query<
                IResponseDataAdmin<ITicket>,
                {
                    title: string;
                    question: string;
                    labels: string[];
                    fileAttachments: string[];
                    answer: string[];
                    page: number;
                    limit: number;
                }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: {
                        title: query.title,
                        question: query.question,
                        labels: query.labels,
                        fileAttachments: query.fileAttachments,
                        answer: query.answer,
                        page: query.page,
                        limit: query.limit,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Ticket" as const, id })),
                              { type: "Ticket", id: "Ticket-LIST" },
                          ]
                        : [{ type: "Ticket", id: "Ticket-LIST" }],
            }),
            getTicketById: builder.query<ITicket, { id: string }>({
                query: (query) => ({
                    url: `${BASE}/${query.id}`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            deleteTicket: builder.mutation<any, { id: string }>({
                query: (query) => ({
                    url: `${BASE}/${query.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Ticket"],
            }),
            deleteMultiTicket: builder.mutation<any, { ids: Array<string> }>({
                query: (query) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: query.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Ticket"],
            }),
            createTicketAnswer: builder.mutation<any, { idTicket: string; answer: string; rate: number }>({
                query: (query) => ({
                    url: `${BASE}/answer/${query.idTicket}`,
                    method: "POST",
                    body: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["TicketAnswer"],
            }),
            createTicketAnswerReply: builder.mutation<any, { ticketID: string; answer: string; rate: number }>({
                query: (query) => ({
                    url: `${BASE}/answer/${query.ticketID}/reply`,
                    method: "POST",
                    body: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["TicketAnswer"],
            }),

            updateTicketAnswerReply: builder.mutation<any, { ticketID: string }>({
                query: (query) => ({
                    url: `${BASE}/answer/${query.ticketID}`,
                    method: "PATCH",
                    body: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["TicketAnswer"],
            }),
        };
    },
});

export const {
    useDeleteMultiTicketMutation,
    useDeleteTicketMutation,
    useGetTicketByIdQuery,
    useGetListTicketPaginateQuery,
    useGetListTicketQuery,
    useLazyGetTicketByIdQuery,
    useLazyGetListTicketPaginateQuery,
    useLazyGetListTicketQuery,
    useCreateTicketAnswerMutation,
    useCreateTicketAnswerReplyMutation,
    useUpdateTicketAnswerReplyMutation,
} = ticketApi;
