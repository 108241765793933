import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    PlusCircleFilled,
    PlusCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import iconApprove from "@assets/images/approve.svg";
import { Pagination } from "@components/pagination";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_DELETE_ERROR, TOAST_DELETE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts/index";
import {
    useDeleteBlogMutation,
    useDeleteMultiBlogMutation,
    useGetListBlogPaginateQuery,
    useLazyGetBlogByIdQuery,
    useUpdateApproveBlogMutation,
    useUpdateSchoolTagsMutation,
} from "@redux/queries/admin/admin.approve.bog";
import { Button, Form, Image, Modal, Row, Select, Table, Tag, Tooltip } from "antd";
import DOMPurify from "dompurify";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { listApproveBlog } from "./approve.enum";
import FilterBlog from "./components/FilterBlog";
import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import HandleForm from "./components/HandleForm";

const ApproveBlog = () => {
    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });
    const [filter, setFilter] = useState<any>({});
    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);

    const { data: list_school, isLoading: isLoadingSchool, isFetching: isFetchingSchool } = useGetListSchoolQuery();

    const [updateSchoolTags] = useUpdateSchoolTagsMutation();

    const [modal, contextHolder] = Modal.useModal();

    const { showToast } = useToast();

    const { data, isLoading, isFetching } = useGetListBlogPaginateQuery(query as any);
    const [approveBlog] = useUpdateApproveBlogMutation();
    const [deleteBlog] = useDeleteBlogMutation();
    const [deleteMultiBlog] = useDeleteMultiBlogMutation();
    const [getInforById, { data: inforBlof }] = useLazyGetBlogByIdQuery();
    const handleGetInforBlog = async (id: string) => {
        const record = await getInforById({ id });
        setDataEdit(record?.data);
    };

    const [openAddTag, setOpenAddTag] = useState(false);
    const [schoolTags, setSchoolTags] = useState<any[]>([]);
    const [selectedBlog, setSelectedBlog] = useState<any>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleDelete = async (id: string) => {
        const result = await deleteBlog({
            id: id,
        });

        if ("error" in result) {
            showToast(TOAST_DELETE_ERROR);
        }

        if ("data" in result) {
            showToast(TOAST_DELETE_SUCCESS);
        }
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiBlog({
            ids: selectedRowKeys as any,
        });
        if ("error" in result) {
            showToast(TOAST_DELETE_ERROR);
        }

        if ("data" in result) {
            showToast(TOAST_DELETE_SUCCESS);
        }
    };

    const onUpdateSchoolTags = async () => {
        if (selectedBlog) {
            try {
                const approved = await updateSchoolTags({ id: selectedBlog, schoolTags: schoolTags });
                if ("data" in approved) {
                    showToast({ ...TOAST_UPDATE_SUCCESS });
                    setOpenAddTag(false);
                    setSchoolTags([]);
                    setSelectedBlog(null);
                } else {
                    showToast({ ...TOAST_UPDATE_ERROR });
                }
            } catch (error) {
                showToast({ ...TOAST_UPDATE_ERROR });
            }
        }
    };

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };

    const columns = [
        {
            title: <div className="text-title-table">Tên người đăng</div>,
            dataIndex: "user",
            key: "user",
            render: (record: any) => {
                return <div className="">{record.firstName + " " + record.lastName}</div>;
            },
        },
        {
            title: <div className="text-title-table">Tiêu đề bài viết</div>,
            key: "title",
            width: 150,
            render: (record: any) => {
                return (
                    <a
                        href={`https://studyinchina.io/blogs/${record.slug}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {record.title}
                    </a>
                );
            },
        },
        // {
        //     title: <div className="text-title-table">Nội dung</div>,
        //     key: "content",
        //     dataIndex: "content",
        //     render: (content: any) => {
        //         return (
        //             <div
        //                 dangerouslySetInnerHTML={{
        //                     __html: DOMPurify.sanitize(content),
        //                 }}
        //                 className="content padding-top-16"
        //             />
        //         );
        //     },
        // },
        {
            title: <div className="text-title-table">Ảnh</div>,
            dataIndex: "thumbnail",
            key: "thumbnail",
            render: (thumbnail: string[]) => {
                return (
                    <Image
                        src={"" + process.env.REACT_APP_CDN + thumbnail}
                        width={100}
                    />
                );
            },
        },
        {
            title: <div className="text-title-table">Tên danh mục</div>,
            dataIndex: "category",
            key: "category",
            render: (category: any) => {
                const title = category?.language.find((item: any) => item.lang === "vi")?.title;
                return <div className="">{title}</div>;
            },
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            dataIndex: "approved",
            key: "approved",
            render: (approved: boolean) => {
                const statusItem = listApproveBlog.find((item) => item.value === approved?.toString());
                return <Tag color={statusItem?.color}>{statusItem?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Ngày cập nhật</div>,
            key: "updatedAt",
            dataIndex: "updatedAt",
            render: (updatedAt: any) => {
                return moment(updatedAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            key: "action",
            fixed: "right" as any,
            render: (record: any) => {
                return (
                    <Row>
                        <Tooltip title="Thêm tag trường">
                            <PlusCircleOutlined
                                style={{ fontSize: "20px" }}
                                onClick={() => {
                                    setSchoolTags(record.schoolTags || []);
                                    setOpenAddTag(true);
                                    setSelectedBlog(record.id);
                                }}
                            />
                        </Tooltip>

                        {!record?.approved ? (
                            <Button
                                className="border-0 bg-transparent"
                                onClick={() => confirmApproveBlog(record.id)}
                            >
                                <img
                                    src={iconApprove}
                                    alt="Approve Icon"
                                />
                            </Button>
                        ) : null}

                        <div
                            className="group-icon-action"
                            key={record.id}
                        >
                            <button
                                type="button"
                                className="text-green"
                                onClick={() => {
                                    setOpenHandleForm(true);
                                    handleGetInforBlog(record.id);
                                }}
                            >
                                <i className="bi-pencil-square"></i>
                            </button>
                            <button
                                type="button"
                                className="text-red"
                                onClick={() => confirmDeleteRecord(record.id)}
                            >
                                <i className="bi-trash"></i>
                            </button>
                        </div>
                    </Row>
                );
            },
        },
    ];

    const handleApprove = async (id: string) => {
        try {
            const approved = await approveBlog({ id: id });
            if ("data" in approved) {
                showToast({ ...TOAST_UPDATE_SUCCESS });
            } else {
                showToast({ ...TOAST_UPDATE_ERROR });
            }
        } catch (error) {
            showToast({ ...TOAST_UPDATE_ERROR });
        }
    };

    const confirmApproveBlog = (id: string) => {
        modal.confirm({
            title: "Thông báo",
            icon: <CheckCircleOutlined />,
            content: "Bạn có chắc chắn muốn duyệt bản ghi này?",
            okText: "Duyệt",
            cancelText: "Hủy",
            onOk() {
                handleApprove(id);
            },
            centered: true,
        });
    };

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterBlog
                                        data={filter}
                                        onSearch={(value: any) => {
                                            setFilter(value);
                                        }}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => setOpenHandleForm(true)}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                    expandable={{
                                        expandIconColumnIndex: -1,
                                        // expandedRowRender: (e: any) => {
                                        //     return (
                                        //         <div
                                        //             dangerouslySetInnerHTML={{
                                        //                 __html: DOMPurify.sanitize(e.content),
                                        //             }}
                                        //             className="content padding-top-16"
                                        //         />
                                        //     );
                                        // },
                                    }}
                                />

                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <Modal
                    open={openAddTag}
                    onCancel={() => {
                        setOpenAddTag(false);
                        setSchoolTags([]);
                        setSelectedBlog(null);
                    }}
                    title="Thêm tag trường"
                    onOk={() => onUpdateSchoolTags()}
                >
                    <div className="form-group">
                        <div className="form-floating">
                            <Select
                                mode="multiple"
                                className="form-control form-floating"
                                bordered={false}
                                showSearch
                                allowClear
                                value={schoolTags}
                                onChange={(v: any) => {
                                    setSchoolTags(v);
                                }}
                                filterOption={(input, option) =>
                                    (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {list_school &&
                                    list_school.map((o: any, i: any) => {
                                        return (
                                            <Select.Option
                                                key={o.id}
                                                value={o.id}
                                            >
                                                {o.language[0].name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>

                            <label>Trường</label>
                        </div>
                    </div>
                </Modal>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(v) => setDataEdit(v)}
                />
                {contextHolder}
            </main>
        </div>
    );
};

export default ApproveBlog;
