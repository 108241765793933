import "@assets/css/vendor.min.css";
import "@assets/css/layout.css";
import "@assets/css/media-screen.css";
import "@assets/js/snow.min.js";
import "@assets/css/index.css";
import "@assets/css/custom.css";

// import "@assets/js/vendor.min.js";
import "@assets/js/script";
import "./App.css";

import "swiper/css";
import { PrivateRoute } from "@components/private-route/PrivateRoute";
import { listRoleAdmins } from "@consts";
import Layout from "@layout";
import Apply from "@pages/admin/apply";
import ApplyGrant from "@pages/admin/apply-grant";
import ApplyPayment from "@pages/admin/apply-payment";
import ApplyProcess from "@pages/admin/apply-process";
import ApplyStep from "@pages/admin/apply-step";
import ApplyTask from "@pages/admin/apply-task";
import ApplyAdd from "@pages/admin/apply/create";
import Area from "@pages/admin/area2";
import City from "@pages/admin/city2";
import Majors from "@pages/admin/majors";
import Majors2 from "@pages/admin/majors2";
import Project from "@pages/admin/project";
import Scholarship from "@pages/admin/scholarship";
import ScholarshipPolicy from "@pages/admin/scholarship-policy2";
import ScholarshipType from "@pages/admin/scholarship-type";
import Scholarship2 from "@pages/admin/scholarship2";
import AdminSchool from "@pages/admin/school";
import SchoolCategory from "@pages/admin/school-category";
import SchoolFaq from "@pages/admin/school-faq";
import SchoolNews from "@pages/admin/school-news";
import AdminSchoolNewsAdd from "@pages/admin/school-news/create";
import SchoolService from "@pages/admin/school-service";
import AdminSchoolAdd from "@pages/admin/school/create";
import Specialized2 from "@pages/admin/specialized2";
import Step from "@pages/admin/step";
import Traning from "@pages/admin/training";
import User from "@pages/admin/users";
import UserCustomer from "@pages/admin/users-customer";
import UserEmployee from "@pages/admin/users-employee";
import ConfirmEmail from "@pages/auth/ConfirmEmail";
import FogotPass from "@pages/auth/ForgotPass";
import Login from "@pages/auth/Login";
import Register from "@pages/auth/Register";
import ResetPass from "@pages/auth/ResetPass";
import SocialAuth from "@pages/auth/SocialAuth";
import Dashboard from "@pages/dashboard";
import Intro from "@pages/intro";
import Privacy from "@pages/privacy";
import DetailSchool from "@pages/pro-tool/schools/DetailSchool";
import Schools from "@pages/pro-tool/schools/ListSchool";
import SuggestSchool from "@pages/pro-tool/suggest-schools";
import TermOfUse from "@pages/term-of-use";
import Profile from "@pages/user/profile";
import { useLazyGetUserProfileQuery } from "@redux/queries/user.api";
import { authSelector, setReferralCode } from "@redux/slices/auth.slice";
import { history } from "@utils/helper-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Deposit from "@pages/admin/deposit";
import Blogs from "@pages/blogs";
import DetailBlog from "@pages/blogs/DetailBlog";
import SchoolSpecialized from "@pages/admin/school-specialized";
import News from "@pages/news";
import NewsDetails from "@pages/news/NewsDetails";
import Compare from "@pages/compare";
import Team from "@pages/admin/team";
import UserTeam from "@pages/admin/users-team";
import ScholarshipUser from "@pages/pro-tool/scholarship";
import SpecializedUser from "@pages/pro-tool/specialized";
import Notification from "@pages/admin/notification";
import ScholarshipDetails from "@pages/pro-tool/scholarship/ScholarshipDetails";
import SpecializedDetails from "@pages/pro-tool/specialized/SpecializedDetails";
import SchoolReview from "@pages/admin/school-review";
import Consultation from "@pages/admin/consultation";
import { SOCKET_URL, socket } from "./socket";
import { message } from "antd";
import PaymentMethod from "@pages/admin/payment-method";
import ApproveBlog from "@pages/admin/approve-blog";
import Payment from "@pages/admin/payment";
import renderHTML from "react-render-html";
import Ticket from "@pages/ticket";
import { io, Socket } from "socket.io-client";
import CreateTicket from "@pages/ticket/CreateTicket";
import Role from "@pages/admin/role";
import ApplyPassed from "@pages/admin/apply-passed";
import ListServices from "@pages/pro-tool/service/ListServices";
import TicketDetails from "@pages/ticket/TicketDetails";
import ApplyDashBoard from "@pages/dashboard/Apply";
import RevenueDashBoard from "@pages/dashboard/Revenue";
import ServiceDetails from "@pages/pro-tool/service/ServiceDetails";
import CategoryBlog from "@pages/admin/category-blog";
import Partner from "@pages/admin/partner";
import Ads from "@pages/admin/ads";
import FromCreateSpecialized from "@pages/admin/specialized2/components/FromCreateSpecialized";

function App() {
    (history as any).navigate = useNavigate();
    (history as any).location = useLocation();
    const dispatch = useDispatch();

    const [messageApi, contextHolder] = message.useMessage();

    const { accessToken, type, user } = useSelector(authSelector);
    const isAdmin = user?.isAdmin || (user?.role && listRoleAdmins.includes(user?.role));

    const [getUserInfo] = useLazyGetUserProfileQuery();
    const [searchParams] = useSearchParams();
    const refId = searchParams.get("refid") || "";

    useEffect(() => {
        if (refId) {
            dispatch(setReferralCode(refId));
        }
    }, [refId]);

    useEffect(() => {
        if (accessToken) {
            getUserInfo();
        }
    }, [accessToken]);

    const handleSocketEvent = (event: any) => {
        messageApi.open({
            type: "info",
            icon: <></>,
            content: (
                <div>
                    <div className="h5 d-flex align-items-center justify-content-center gap-2">
                        <i
                            className="bi-megaphone-fill"
                            style={{ color: "#733aea" }}
                        />
                        Hệ thống thông báo
                    </div>
                    <div className="h6">{event?.[0]?.title}</div>
                    <p
                        className="d-flex justify-content-center gap-1"
                        style={{ minWidth: "300px", maxWidth: "65vw" }}
                    >
                        {renderHTML(event?.[0]?.content)}
                    </p>
                </div>
            ),
            duration: 5,
        });
    };

    // socket event
    useEffect(() => {
        const onSocket = (event: any) => {
            handleSocketEvent(event);
        };

        socket.on("system", onSocket);

        return () => {
            socket.off("system", onSocket);
        };
    }, []);

    // socket event auth
    // useEffect(() => {
    //     if (!accessToken || !type) return;

    //     const headers = { Authorization: `${type} ${accessToken}` };

    //     const socketAuth: Socket = io(SOCKET_URL, {
    //         transports: ["websocket"],
    //         extraHeaders: headers,
    //         transportOptions: {
    //             websocket: {
    //                 extraHeaders: headers,
    //             },
    //         },
    //         // auth: {
    //         //     Authorization: headers.Authorization,
    //         // },
    //     });
    //     const onSocket = (event: any) => {
    //         handleSocketEvent(event);
    //     };

    //     socketAuth.on("system", onSocket);

    //     return () => {
    //         socketAuth.off("system", onSocket);
    //     };
    // }, [type, accessToken]);

    // useEffect(() => {
    //     if (isLogin) {
    //         if (user?.startWelcome) {
    //             history.navigate("/");
    //             return;
    //         }
    //         history.navigate("/intro");
    //     }
    // }, []);

    return (
        <>
            {contextHolder}
            <Routes>
                <Route
                    path="/"
                    element={<Layout />}
                >
                    <Route
                        path="/"
                        element={<Dashboard />}
                    />

                    <Route
                        path="/dashboard/common"
                        element={<Dashboard />}
                    />

                    <Route
                        path="/dashboard/revenue"
                        element={<RevenueDashBoard />}
                    />

                    <Route
                        path="/dashboard/apply"
                        element={<ApplyDashBoard />}
                    />

                    {/* <Route
                        path="pro-tool/suggest-schools"
                        element={<SuggestSchool />}
                    />

                    <Route
                        path="pro-tool/schools"
                        element={<Schools />}
                    />

                    <Route
                        path="pro-tool/scholarship"
                        element={<ScholarshipUser />}
                    />

                    <Route
                        path="pro-tool/specialized"
                        element={<SpecializedUser />}
                    />

                    <Route
                        path="pro-tool/service"
                        element={<ListServices />}
                    />

                    <Route
                        path="pro-tool/service/:id"
                        element={<ServiceDetails />}
                    /> */}

                    {/* 
                    <Route
                        path="/blogs"
                        element={
                                <Blogs />
                        }
                    /> */}

                    <Route
                        path="/blogs/:id"
                        element={<DetailBlog />}
                    />

                    {/* <Route
                        path="/news"
                        element={<News />}
                    /> */}

                    {/* <Route
                        path="/news/:id"
                        element={<NewsDetails />}
                    /> */}

                    <Route
                        path="/scholarship/:id"
                        element={<ScholarshipDetails />}
                    />

                    <Route
                        path="/specialized/:id"
                        element={<SpecializedDetails />}
                    />

                    <Route
                        path="/pro-tool/compare"
                        element={<Compare />}
                    />

                    <Route
                        path="/schools/:id"
                        element={<DetailSchool />}
                    />

                    <Route
                        path="/create-ticket"
                        element={
                            <PrivateRoute>
                                <CreateTicket />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/ticket/:id"
                        element={
                            <PrivateRoute>
                                <TicketDetails />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/user/profile"
                        element={
                            <PrivateRoute>
                                <Profile />
                            </PrivateRoute>
                        }
                    />

                    {/* User */}
                    {!isAdmin && !user?.startWelcome && (
                        <Route
                            path="/intro"
                            element={
                                <PrivateRoute>
                                    <Intro />
                                </PrivateRoute>
                            }
                        />
                    )}

                    {/* Admin */}
                    {isAdmin && (
                        <>
                            <Route
                                path="/admin/area"
                                element={
                                    <PrivateRoute>
                                        <Area />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/city"
                                element={
                                    <PrivateRoute>
                                        <City />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/majors2"
                                element={
                                    <PrivateRoute>
                                        <Majors />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/majors"
                                element={
                                    <PrivateRoute>
                                        <Majors2 />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/scholarship2"
                                element={
                                    <PrivateRoute>
                                        <Scholarship />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/scholarship"
                                element={
                                    <PrivateRoute>
                                        <Scholarship2 />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/admin/specialized"
                                element={
                                    <PrivateRoute>
                                        <Specialized2 />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/admin/specialized/add"
                                element={
                                    <PrivateRoute>
                                        <FromCreateSpecialized />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/specialized/edit/:id"
                                element={
                                    <PrivateRoute>
                                        <FromCreateSpecialized />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/training"
                                element={
                                    <PrivateRoute>
                                        <Traning />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/school"
                                element={
                                    <PrivateRoute>
                                        <AdminSchool />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/school/add"
                                element={
                                    <PrivateRoute>
                                        <AdminSchoolAdd />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/admin/school/edit/:id"
                                element={
                                    <PrivateRoute>
                                        <AdminSchoolAdd />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/school-category"
                                element={
                                    <PrivateRoute>
                                        <SchoolCategory />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/school-service"
                                element={
                                    <PrivateRoute>
                                        <SchoolService />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/school-specialized"
                                element={
                                    <PrivateRoute>
                                        <SchoolSpecialized />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/school-faq"
                                element={
                                    <PrivateRoute>
                                        <SchoolFaq />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/school-review"
                                element={
                                    <PrivateRoute>
                                        <SchoolReview />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/school-news"
                                element={
                                    <PrivateRoute>
                                        <SchoolNews />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/school-news/add"
                                element={
                                    <PrivateRoute>
                                        <AdminSchoolNewsAdd />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/school-news/edit/:id"
                                element={
                                    <PrivateRoute>
                                        <AdminSchoolNewsAdd />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/step"
                                element={
                                    <PrivateRoute>
                                        <Step />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/apply-task"
                                element={
                                    <PrivateRoute>
                                        <ApplyTask />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/apply"
                                element={
                                    <PrivateRoute>
                                        <Apply />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/apply/add"
                                element={
                                    <PrivateRoute>
                                        <ApplyAdd />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/apply/edit/:id"
                                element={
                                    <PrivateRoute>
                                        <ApplyAdd />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/apply-step"
                                element={
                                    <PrivateRoute>
                                        <ApplyStep />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/apply-process"
                                element={
                                    <PrivateRoute>
                                        <ApplyProcess />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/apply-payment"
                                element={
                                    <PrivateRoute>
                                        <ApplyPayment />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/apply-grant"
                                element={
                                    <PrivateRoute>
                                        <ApplyGrant />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/project"
                                element={
                                    <PrivateRoute>
                                        <Project />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/scholarship"
                                element={
                                    <PrivateRoute>
                                        <Scholarship />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/scholarship-type"
                                element={
                                    <PrivateRoute>
                                        <ScholarshipType />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/scholarship-policy"
                                element={
                                    <PrivateRoute>
                                        <ScholarshipPolicy />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin/consultation"
                                element={
                                    <PrivateRoute>
                                        <Consultation />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/admin/ads"
                                element={
                                    <PrivateRoute>
                                        <Ads />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/admin/partner"
                                element={
                                    <PrivateRoute>
                                        <Partner />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/users"
                                element={
                                    <PrivateRoute>
                                        <User />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/users/customer"
                                element={
                                    <PrivateRoute>
                                        <UserCustomer />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/users/employee"
                                element={
                                    <PrivateRoute>
                                        <UserEmployee />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/users/role"
                                element={
                                    <PrivateRoute>
                                        <Role />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/datas/deposit"
                                element={
                                    <PrivateRoute>
                                        <Deposit />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/datas/apply-passed"
                                element={
                                    <PrivateRoute>
                                        <ApplyPassed />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/users/team"
                                element={
                                    <PrivateRoute>
                                        <Team />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/users/team-user"
                                element={
                                    <PrivateRoute>
                                        <UserTeam />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/datas/notification"
                                element={
                                    <PrivateRoute>
                                        <Notification />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/dashboard/payment-method"
                                element={
                                    <PrivateRoute>
                                        <PaymentMethod />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/datas/approve-blog"
                                element={
                                    <PrivateRoute>
                                        <ApproveBlog />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/datas/category-blog"
                                element={
                                    <PrivateRoute>
                                        <CategoryBlog />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/datas/ticket"
                                element={
                                    <PrivateRoute>
                                        <Ticket />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/dashboard/payment"
                                element={
                                    <PrivateRoute>
                                        <Payment />
                                    </PrivateRoute>
                                }
                            />
                        </>
                    )}
                </Route>
                <Route
                    path="/login"
                    element={<Login />}
                />
                <Route
                    path="/terms-of-use"
                    element={<TermOfUse />}
                />
                <Route
                    path="/privacy-policy"
                    element={<Privacy />}
                />
                <Route
                    path="/register"
                    element={<Register />}
                />
                <Route
                    path="/forgot-pass"
                    element={<FogotPass />}
                />
                <Route
                    path="/reset-pass"
                    element={<ResetPass />}
                />
                <Route
                    path="/social-auth"
                    element={<SocialAuth />}
                />
                <Route
                    path="/confirm-email"
                    element={<ConfirmEmail />}
                />

                <Route
                    path="*"
                    element={<Navigate to="/" />}
                />
            </Routes>
        </>
    );
}

export default App;
