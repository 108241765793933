export enum ADMISSION_PERIOD {
    SEPTEMBER = "SEPTEMBER",
    MARCH = "MARCH",
    SUMMER_CAMP = "SUMMER_CAMP",
    WINTER_CAMP = "WINTER_CAMP",
}

export enum E_TRAINING_FORMAT {
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE",
}

export enum RELATES {
    AREA = "AREA",
    SCHOLARSHIP = "SCHOLARSHIP",
    SPECIALIZED = "SPECIALIZED",
    ALL = "ALL",
    SCHOOL = "SCHOOL",
}
export enum TYPE_OF_ADMISSION {
    SCHOLARSHIP = "SCHOLARSHIP",
    SELF_FUNDED = "SELF_FUNDED",
}

export enum SUPPORT_MODE {
    SUBSIDIZED = "SUBSIDIZED",
    NON_SUBSIDIZED = "NON_SUBSIDIZED",
}

export enum LANGUAGE {
    CN = "CN",
    EN = "EN",
    VN = "VI",
}

export const TRAINING_FORMAT = [{ value: "ONLINE" }, { value: "OFFLINE" }];

export const RELATES_OPTIONS = [
    { value: RELATES.ALL, label: "Tất cả" },
    { value: RELATES.AREA, label: "Khu vực" },
    { value: RELATES.SCHOLARSHIP, label: "Thông tin học bổng" },
    { value: RELATES.SCHOOL, label: "Trường" },
    { value: RELATES.SPECIALIZED, label: "Chuyên ngành" },
];

export const PROGRAMS_LANGUAGE = [
    { value: LANGUAGE.CN, label: "Tiếng Trung" },
    { value: LANGUAGE.EN, label: "Tiếng Anh" },
    { value: LANGUAGE.VN, label: "Tiếng Việt" },
];

export const TRAINING_LANGUAGE = [
    { value: LANGUAGE.CN, label: "Tiếng Trung" },
    { value: LANGUAGE.EN, label: "Tiếng Anh" },
    { value: LANGUAGE.VN, label: "Tiếng Việt" },
];

export const SUPPORT_MODE_OPTIONS = [
    { value: SUPPORT_MODE.SUBSIDIZED, label: "Được trợ cấp" },
    { value: SUPPORT_MODE.NON_SUBSIDIZED, label: "Không được trợ cấp" },
];

export const TYPE_OF_ADMISSION_OPTIONS = [
    { value: TYPE_OF_ADMISSION.SCHOLARSHIP, label: "Học bổng" },
    { value: TYPE_OF_ADMISSION.SELF_FUNDED, label: "Tự phí" },
];

export const ADMISSION_PERIOD_OPTIONS = [
    { value: ADMISSION_PERIOD.SEPTEMBER, label: "Tháng 9" },
    { value: ADMISSION_PERIOD.MARCH, label: "Tháng 3" },
    { value: ADMISSION_PERIOD.SUMMER_CAMP, label: "Trại hè" },
    { value: ADMISSION_PERIOD.WINTER_CAMP, label: "Trại đông" },
];
