import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/step";

export const adminStepApi = createApi({
    reducerPath: "adminStepApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Step"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListStep: builder.query<Array<IStep>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "Step" as const, id })),
                              { type: "Step", id: "Step-LIST" },
                          ]
                        : [{ type: "Step", id: "Step-LIST" }],
            }),
            getListStepPaginate: builder.query<
                IResponseDataAdmin<IStep>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: {
                        page: query.page,
                        limit: query.limit,
                        name: query.name,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Step" as const, id })),
                              { type: "Step", id: "Step-LIST" },
                          ]
                        : [{ type: "Step", id: "Step-LIST" }],
            }),
            createStep: builder.mutation<any, IStepInput>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Step"],
            }),
            updateStep: builder.mutation<any, IStepInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Step"],
            }),
            deleteStep: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Step"],
            }),
            deleteMultiStep: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Step"],
            }),
        };
    },
});

export const {
    useGetListStepQuery,
    useGetListStepPaginateQuery,
    useLazyGetListStepPaginateQuery,
    useCreateStepMutation,
    useUpdateStepMutation,
    useDeleteStepMutation,
    useDeleteMultiStepMutation,
} = adminStepApi;
