import store from "@redux/store";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";

interface IProps {
    value: string;
    onChange: (value: string) => void;
}

export default function TinyMCE(props: IProps) {
    const [editValue, setEditValue] = useState(props.value);
    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;
    const handleImageUpload = (blobInfo: any, progress: any, failure: any) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${process.env.REACT_APP_HOST}/api/file/upload/original`, true);
            xhr.setRequestHeader("Authorization", `${type} ${token}`);

            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());

            xhr.upload.onprogress = (e) => {
                progress((e.loaded / e.total) * 100);
                if (progress && typeof progress === "function") {
                    const percent = 0;
                    progress(percent);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 403) {
                    reject({ message: "HTTP Error: " + xhr.status, remove: true });
                    return;
                }

                if (xhr.status < 200 || xhr.status >= 300) {
                    reject("HTTP Error: " + xhr.status);
                    return;
                }

                const json = JSON.parse(xhr.responseText);
                console.log(json.url);

                if (!json || typeof json.url != "string") {
                    reject("Invalid JSON: " + xhr.responseText);
                    return;
                }

                resolve(`${process.env.REACT_APP_CDN}assets/${json.url}`);
            };

            xhr.onerror = () => {
                reject({ message: "Image upload failed", remove: true });
                if (failure && typeof failure === "function") {
                    failure("Image upload failed");
                }
            };

            xhr.send(formData);
        });
    };

    const listAPIKey = [
        "6g63i9b030vfez01sxt5bajsk0xiprrosax17r0oh00hwhk4",
        "bgvrj1n9h0r41dgsp51e26z5suuqtn6udkdsutni550xi88r",
        "lve3uqkt0ay8yxsft3gefpu70pw51xkkuixiln8hl8x5dkq6",
        "v1a83lgmk0i57ig9qsq2ed5cfuijovagg6wgr4bn46noqhfo",
        "aaala3zoq1rgi17feu58qm9tobvmtd52ywxlre0rs4bpl4zi",
        "nrertt2pqx5tgmqs0mukgybsxpf7zr01c2pu7m67sc1ra3bw",
        "sj4eauy9ry6c5agb8n9mspwmpej14a4j3opvdm61e1xshjje",
        "wis4x5mopsysae5cy9ysodujbjzi7dvttc87vwr8351ul1ti",
        "xur47jvd82kgx0zbgrdjl2pw48q5b5l6jiv435s776ckkf9t",
        "sybfh8wnm2kgdnob601ryft911fzcmzyy6x6wxcmcbisdow5",
        "hurfbgwge5cs4sxqng11stmn7gn8l9p7l8ohyxz6rdlte4ei",
        "ynfbgczoyo8qn31j67gbbqdz7wp26r2g6aq62yu8he6nlksq",
        "4azsbqf3ij2ttv12jhjn7gn2172rwzw0346earglqtppoagk",
        "jr315ermst6qf9fn67l70yvis3qc202j5l5hwx9zsb00kn9m",
        "2qpb4uxjot2g7w5onv9qf9n2lwxdxkcqqpcvvk1zpnzc4vvo",
        "orw480xyofp2knaz80i0axmf2czdkfgeyqkrq8cppmcvsmp4",
        "87o9ntwzm7n8zf7rbzznr9ttya9vpizazy29q2cbng1w3pvv",
        "j5tuduhj9qbzge4e70jjb0mkbf1vsi3v7swlc44u3jo351bc",
        "348m0vwo74wu1kkbmu9i30uzh53rdkeovdqc6m1j2xx7io4e",
        "on2wwv9rjdl8e8b93t7k5bbckyv20d4s4lq8wg9gxnyhaazg",
    ];

    return (
        <>
            <Editor
                apiKey={listAPIKey[Math.floor(Math.random() * listAPIKey.length)]}
                initialValue={props.value}
                // value={props.value}
                onEditorChange={(a, editor) => {
                    setEditValue(a);
                }}
                onBlur={() => props?.onChange(editValue)}
                init={{
                    body_class: "text-editor",
                    height: 500,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | styles | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    images_upload_url: `${process.env.REACT_APP_HOST}/api/file/upload/original`,
                    automatic_uploads: true,
                    images_reuse_filename: true,
                    images_upload_handler: handleImageUpload as any,
                    language: "vi",
                }}
            />
        </>
    );
}
