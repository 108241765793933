import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/apply/payment";

export const adminApplyPaymentApi = createApi({
    reducerPath: "adminApplyPaymentApi",
    keepUnusedDataFor: 30,
    tagTypes: ["ApplyPayment"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListApplyPayment: builder.query<Array<IApplyPayment>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "ApplyPayment" as const, id })),
                              { type: "ApplyPayment", id: "ApplyPayment-LIST" },
                          ]
                        : [{ type: "ApplyPayment", id: "ApplyPayment-LIST" }],
            }),
            getListApplyPaymentPaginate: builder.query<IResponseDataAdmin<IApplyPayment>, any>({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "ApplyPayment" as const, id })),
                              { type: "ApplyPayment", id: "ApplyPayment-LIST" },
                          ]
                        : [{ type: "ApplyPayment", id: "ApplyPayment-LIST" }],
            }),
            paidApplyPayment: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/paid/${params.id}`,
                    method: "PATCH",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyPayment"],
            }),
            createApplyPayment: builder.mutation<any, any>({
                query: (params) => ({
                    url: `${BASE}/payment/${params.applyId}`,
                    method: "POST",
                    body: _.omit(params, ["applyId"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["ApplyPayment"],
            }),
        };
    },
});

export const {
    useGetListApplyPaymentQuery,
    useGetListApplyPaymentPaginateQuery,
    useLazyGetListApplyPaymentPaginateQuery,
    useCreateApplyPaymentMutation,
    usePaidApplyPaymentMutation,
} = adminApplyPaymentApi;
