import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";

const BASE = "/api/school/faq";

export const adminSchoolFaqApi = createApi({
    reducerPath: "adminSchoolFaqApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["SchoolFaq"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSchoolFaq: builder.query<Array<ISchoolFaq>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "SchoolFaq" as const, id })),
                              { type: "SchoolFaq", id: "SchoolFaq-LIST" },
                          ]
                        : [{ type: "SchoolFaq", id: "SchoolFaq-LIST" }],
            }),
            getListSchoolFaqPaginate: builder.query<
                IResponseDataAdmin<ISchoolFaq>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "SchoolFaq" as const, id })),
                              { type: "SchoolFaq", id: "SchoolFaq-LIST" },
                          ]
                        : [{ type: "SchoolFaq", id: "SchoolFaq-LIST" }],
            }),
            createSchoolFaq: builder.mutation<any, ISchoolFaq>({
                query: (params) => ({
                    url: `${BASE}/${params.schoolId}`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolFaq"],
            }),
            updateSchoolFaq: builder.mutation<any, ISchoolFaq>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"]),
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolFaq"],
            }),
            deleteSchoolFaq: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolFaq"],
            }),
            deleteMultiSchoolFaq: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolFaq"],
            }),
        };
    },
});

export const {
    useGetListSchoolFaqQuery,
    useGetListSchoolFaqPaginateQuery,
    useLazyGetListSchoolFaqPaginateQuery,
    useCreateSchoolFaqMutation,
    useUpdateSchoolFaqMutation,
    useDeleteSchoolFaqMutation,
    useDeleteMultiSchoolFaqMutation,
} = adminSchoolFaqApi;
